import { render, staticRenderFns } from "./startVideo.vue?vue&type=template&id=77a79120&scoped=true"
import script from "./startVideo.vue?vue&type=script&lang=js"
export * from "./startVideo.vue?vue&type=script&lang=js"
import style0 from "./_startVideo.scss?vue&type=style&index=0&id=77a79120&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77a79120",
  null
  
)

export default component.exports