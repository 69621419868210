<template>
    <div class="startVideo">
        <div class="startVideo__overlay"></div>

        <div class="startVideo__content">
            <div class="startVideo__text">
                <p>Wir bearbeiten Ihre Oberflächen.</p>
                <p>Ganz nach Ihren Vorstellungen.</p>
            </div>

            <div class="startVideo__buttonRow">
                <buttonStd :class="'-isLight'" data-aos="fade-right" data-aos-offset="0" :href="'/metallbearbeitung/gleitschleifen.html'">Gleitschleifen</buttonStd>
                <buttonStd :class="'-isLight'" data-aos="fade-right" data-aos-offset="0" data-aos-delay="200" :href="'/metallbearbeitung/strahltechnik.html'">Strahltechnik</buttonStd>
                <buttonStd :class="'-isLight'" data-aos="fade-right" data-aos-offset="0" data-aos-delay="400" :href="'/metallbearbeitung.html'">Weitere Leistungen</buttonStd>
            </div>
        </div>

        <div class="startVideo__video">
            <video autoplay loop muted playsinline>
                <source src="~@/assets/video/weber_start.webm"
                type="video/webm">
                <source src="~@/assets/video/weber_start.mp4"
                type="video/mp4">
                Ihr Browser kann dieses Video nicht wiedergeben.
            </video>
        </div>
    </div>
</template>

<script>
import buttonStd from '../buttonStd/buttonStd.vue';

export default {
    name: 'startVideo',
    components: {
        buttonStd,
    },
};
</script>

<style src="./_startVideo.scss" lang="scss" scoped></style>
