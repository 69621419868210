<template>
    <div id="start" class="mainWrapper">
        <appHeader />
        <main class="mainContent">
            <startVideo />

            <contentWrapper :class="'-isSmall -noPaddingHor'">
                <div class="weberBig">
                    <span class="weberBig__weber">Weber</span>
                    <span class="weberBig__velbert">Velbert</span>
                </div>
            </contentWrapper>

            <contentWrapper :class="'-isBgWhite -hasGrid04'" isBoxed>
                <div class="gridContent04__01">
                    <headline :class="'-isBold'" headlineType="h2">Unsere Verfahren</headline>

                    <div class="textWrapper">
                        <p>Um Ihre metallischen Oberflächen qualitativ hochwertig zu bearbeiten, können wir Ihnen unterschiedliche Verfahren anbieten.</p>
                        <p>Diese unterteilen sich in <b>Gleitschleif- und Strahltechniken</b>.</p>
                    </div>
                </div>
                <div class="gridContent04__02">
                    <linkTileStd data-aos="fade-right" :href="'/metallbearbeitung/gleitschleifen.html'">Gleitschleifen</linkTileStd>
                </div>
                <div class="gridContent04__03">
                    <linkTileStd data-aos="fade-right" data-aos-delay="200" :href="'/metallbearbeitung/kugelpolieren.html'">Kugelpolieren</linkTileStd>
                </div>
                <div class="gridContent04__04">
                    <linkTileStd data-aos="fade-right" data-aos-delay="400" :href="'/metallbearbeitung/chemo-finish-polieren.html'">Chemo-Finish Polieren</linkTileStd>
                </div>
                <div class="gridContent04__05">
                    <linkTileStd data-aos="fade-right" data-aos-delay="600" :href="'/metallbearbeitung/strahltechnik/magnesiumstrahlen.html'">Magnesium-Strahlen</linkTileStd>
                </div>
                <div class="gridContent04__06">
                    <linkTileStd data-aos="fade-right" data-aos-delay="800" :href="'/metallbearbeitung/strahltechnik/haengebahnstrahlen.html'">Hängebahn-Strahlen</linkTileStd>
                </div>
                <div class="gridContent04__07">
                    <linkTileStd data-aos="fade-right" data-aos-delay="1000" :href="'/metallbearbeitung/strahltechnik/muldenbandstrahlen.html'">Muldenband-Strahlen</linkTileStd>
                </div>
            </contentWrapper>

            <contentWrapper :class="'-isSmallBigBottom'" isBoxed>
                <badge />
                <headline :class="'-isBold -isPrimary'" headlineType="h1">Weber <br>Metallbearbeitung <br>aus Velbert</headline>
            </contentWrapper>

            <contentWrapper :class="'-isBgWhite -hasGrid01 -isSmall'" isBoxed>
                <div class="gridContent01__text">
                    <div class="textWrapper">
                        <p>Seit nun 1966 gilt die Weber Metallbearbeitungs GmbH &amp; Co. KG im Bereich der modernen Metallbearbeitung als kompetenter und leistungsstarker Partner.</p>
                        <p>Investitionen in modernste Maschinen, sowie motivierte und qualifizierte Mitarbeiter, sind das Resultat für einwandfreie und bestmögliche Produktqualität.</p>
                        <p>Für weitere Fragen rund um die Metallbearbeitung stehen wir Ihnen gerne jederzeit zur Verfügung. Rufen Sie uns hierzu gerne an oder nutzen Sie unsere Kontaktformular.</p>
                    </div>
                </div>
                <div class="gridContent01__image -hasImgWrapper">
                    <div class="imgWrapper -hasShadow">
                        <figure>
                            <img src="~@/assets/images/weber_metallbearbeitung_velbert_firma_front.jpg" width="1024" height="768">
                        </figure>
                    </div>
                </div>
                <div class="gridContent01__cta">
                    <div class="buttonWrapper -isSmallFullwidth -isVertical -btnSameSize -isRight">
                        <buttonStd data-aos="fade-right" data-aos-offset="0" :href="'/ueber-uns.html'">Über uns</buttonStd>
                        <buttonStd data-aos="fade-right" data-aos-offset="0" data-aos-delay="200" :href="'/kontakt.html'">Kontakt</buttonStd>
                    </div>
                </div>
            </contentWrapper>

            <contentWrapper :class="'-hasGrid05'" isBoxed>
                <div class="gridContent05__01">
                    <headline :class="'-isBold -isWhite'" headlineType="h3">Bearbeitete Oberflächen</headline>

                    <div class="textWrapper -isWhite">
                        <p>Oberflächen können auf unterschiedlichste Art und Weise bearbeitet werden. Je nach Anwendungsfall, Material und Beschaffenheit lassen sich verschiedene Metallbearbeitungen anwenden.<br>Nebenstehend einige Beispiele von bearbeiteten Oberflächen nach einer erfolgreichen Oberflächenbearbeitung durch Gleitschleifen, Kugelpolieren, Sandstrahlen oder Chemo-Finish-Polieren.</p>
                    </div>
                </div>
                <div class="gridContent05__02">
                    <splide :options="splideOptions">
                        <splide-slide>
                            <div class="splide__slide__container">
                                <img src="~@/assets/images/weber_metallbearbeitung_01.jpg" alt="Chemo-Finish-Polieren">
                                <div class="splide__slideCaptionWrapper">
                                    <div class="splide__slideCaptionSub">
                                        Chemo-Finish-Polieren
                                    </div>
                                </div>
                            </div>
                        </splide-slide>
                        <splide-slide>
                            <div class="splide__slide__container">
                                <img src="~@/assets/images/weber_metallbearbeitung_chemo_finish_polieren_01.jpg" alt="Chemo-Finish-Polieren">
                                <div class="splide__slideCaptionWrapper">
                                    <div class="splide__slideCaptionSub">
                                        Chemo-Finish-Polieren
                                    </div>
                                </div>
                            </div>
                        </splide-slide>
                        <splide-slide>
                            <div class="splide__slide__container">
                                <img src="~@/assets/images/weber_metallbearbeitung_gleitschleifen_trowalisieren_01.jpg" alt="Gleitschleifen">
                                <div class="splide__slideCaptionWrapper">
                                    <div class="splide__slideCaptionSub">
                                        Gleitschleifen
                                    </div>
                                </div>
                            </div>
                        </splide-slide>
                        <splide-slide>
                            <div class="splide__slide__container">
                                <img src="~@/assets/images/weber_metallbearbeitung_kugelpoliert_01.jpg" alt="Kugelpolieren">
                                <div class="splide__slideCaptionWrapper">
                                    <div class="splide__slideCaptionSub">
                                        Kugelpolieren
                                    </div>
                                </div>
                            </div>
                        </splide-slide>
                        <splide-slide>
                            <div class="splide__slide__container">
                                <img src="~@/assets/images/weber_metallbearbeitung_strahltechnik_02.jpg" alt="Sandstrahlen">
                                <div class="splide__slideCaptionWrapper">
                                    <div class="splide__slideCaptionSub">
                                        Sandstrahlen
                                    </div>
                                </div>
                            </div>
                        </splide-slide>
                    </splide>
                </div>
            </contentWrapper>
        </main>
        <appFooter />
    </div>
</template>

<script>
import 'normalize.css';
import badge from '@/components/badge/badge.vue';
import appHeader from '@/components/appHeader/appHeader.vue';
import appFooter from '@/components/appFooter/appFooter.vue';
import startVideo from '@/components/startVideo/startVideo.vue';
import contentWrapper from '@/components/contentWrapper/contentWrapper.vue';
import headline from '@/components/headline/headline.vue';
import buttonStd from '@/components/buttonStd/buttonStd.vue';
import linkTileStd from '@/components/linkTileStd/linkTileStd.vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default {
    name: 'App',
    components: {
        badge,
        appHeader,
        appFooter,
        startVideo,
        contentWrapper,
        headline,
        buttonStd,
        linkTileStd,
        Splide,
        SplideSlide,
    },
    data() {
        return {
            splideOptions: {
                autoplay: true,
                type: 'loop',
                autoWidth: true,
                gap: '60px',
                pagination: false,
                arrowPath: 'M30.1786 30.0001C30.6994 30.0001 31.1519 29.8406 31.536 29.5215L31.6964 29.3751L39.375 21.6965C39.7917 21.2798 40 20.7739 40 20.1786C40 19.6578 39.8405 19.2053 39.5215 18.8212L39.375 18.6608L31.6964 10.9822C31.2798 10.5655 30.7738 10.3572 30.1786 10.3572C29.5833 10.3572 29.0774 10.5655 28.6607 10.9822C28.2961 11.3468 28.0911 11.7797 28.0455 12.281L28.0357 12.5001V16.6072L1.07143 16.6072C0.77381 16.6072 0.520833 16.7114 0.3125 16.9197C0.138889 17.0933 0.0376159 17.2979 0.00868059 17.5335L0 17.6786L0 22.6786C0 22.9763 0.104167 23.2292 0.3125 23.4376C0.486111 23.6112 0.690724 23.7124 0.926339 23.7414L1.07143 23.7501L28.0357 23.7501V27.8572C28.0357 28.4524 28.244 28.9584 28.6607 29.3751C29.0774 29.7917 29.5833 30.0001 30.1786 30.0001Z',
                breakpoints: {
                    639: {
                        gap: '0px',
                        autoWidth: false,
                        arrows: false,
                        pagination: true,
                    },
                },
            },
        };
    },
};
</script>

<style src="@/_styles/_globalStyles.scss" lang="scss"></style>
